<style lang="scss" scoped>
.c-main {
  overflow: hidden;
}
/* 
.container__message {
  margin: 0 auto;
  width: 40.6em;
  position: relative;
  height: 28.1em;
  background: transparent;
}

.circle__style.open {
  width: 23em;
  height: 19.87em;
  background: #4d95fc;
  border-radius: 100%;
  padding: 0 40px;
  padding-top: 4.5em;
  padding-bottom: 54px;
  text-align: center;
  color: #fff;
  font-size: 1em;
  position: relative;

  span {
    font-size: 34px;
    font-weight: bold;
  }
  p {
    line-height: 20px;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
  }
  p.main {
    font-size: 34px !important;
    font-weight: bold;
  }
}

.triangulo {
  width: 0;
  height: 0;
  border-left: 48px solid #4d95fc;
  border-top: 12px solid transparent;
  border-bottom: 47px solid transparent;
  position: absolute;
  bottom: 6px;
  right: 3px;
  -webkit-transform: rotate(49deg);
  transform: rotate(49deg);
  z-index: -1;
}

.avatar__alicia__message {
  z-index: -2;
  position: absolute;
  width: 38vw;
  height: 71vh;
  bottom: 0;
  top: 10vh;
  right: 0px;
  left: 10.6em;
}

.avatar__alicia__mobile {
  display: none;
}

.card {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 590px) {
  .container__message {
    // width: 300px !important;
    width: 300px !important;
    margin-bottom: 0px !important;
    height: 37.5em !important;
    overflow: hidden;
  }

  .circle__style.open {
    width: 240px;
    height: 200px;
    padding-top: 40px;

    img {
      height: 24px !important;
    }
  }
  .circle__style.open p.main {
    font-size: 1.25em !important;
  }
  .circle__style.open p {
    font-size: 1em !important;
  }

  .open .triangulo {
    bottom: -30px;
    right: 72px;
    -webkit-transform: rotate(76deg);
    transform: rotate(76deg);
  }

  .circle__style.open span {
    font-size: 1.5em;
  }

  .avatar__alicia__message {
    width: 230px !important;
    top: 150px !important;
    right: -70px;
    bottom: 0;
    display: none !important ;
  }
  .avatar__alicia__mobile {
    left: 130px;
    top: 20px !important;
    display: block;
  }
} */
h2 {
  font-size: 2.5em;
  font-weight: bold;
  color: #4d95fc;
  margin-bottom: 0px;
}

</style>

<template>
  <div class="container">
    <div class="w-100 text-center py-4 d-flex justify-content-center align-items-center my-4  ">
      <h2>¡ Hola {{ firstName }} !</h2>
    </div>

    <div class="pt-3">
      <!-- Banner -->
      <img class="img-fluid  rounded-bottom" src="../assets/img/6X6 ALICIA QR GESTION WHATSAAP_page-0001.jpg" />
    </div>
  </div>
  <!-- <div class="text-center m-0"> 
    

    <div class="container__message" style="margin-top:2.5em;">
      <div class="circle__style open">
        <p class="main">
          ¡Bienvenido/a!
        </p>
        <p>{{ firstName.toUpperCase() }}</p>
        <p>
          a la plataforma del
        </p>
        <p>
          Club El Comercio
        </p>
        <div class="triangulo"></div>
      </div>
      <img class="avatar__alicia__message" src="../assets/img/alicia_baner.png"/>
      <img class="avatar__alicia__mobile" src="../assets/img/alicia_3.jpg" />
    </div>
  </div> -->
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      //capitalize first letter
      firstName: localStorage.getItem("name").split(" ")[0].charAt(0).toUpperCase() + localStorage.getItem("name").split(" ")[0].toLowerCase().slice(1),
    };
  },
  mounted() {
    $(".tolttip-message").hide();
  },
  methods: {},
};
</script>
